/*!
 * Bootstrap v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
// @import "tables";
// @import "forms";
@import "buttons";
@import "transitions";
// @import "dropdown";
// @import "button-group";
@import "nav";
@import "navbar";
// @import "card";
// @import "accordion";
// @import "breadcrumb";
// @import "pagination";
// @import "badge";
// @import "alert";
// @import "progress";
// @import "list-group";
@import "close";
// @import "toasts";
@import "modal";
// @import "tooltip";
// @import "popover";
// @import "carousel";
// @import "spinners";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack


.sidebar {
    position: fixed;
    top: 0;
    /* rtl:raw:
    right: 0;
    */
    bottom: 0;
    /* rtl:remove */
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }


  .main-list
  {
      height: 85%;
  }
    .sidebar {
      top: 4.5rem;
    }
  @media (min-width: 768px) {
    .main-list
    {
        height: 100%;
    }
    .sidebar {
      top: 0rem;
    }
  }

  .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  }
  
  .navbar .navbar-toggler {
    top: 0.85rem;
    right: 1rem;
  }